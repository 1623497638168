/** @jsx jsx */
import { GatsbyImage } from "gatsby-plugin-image"
import { Heading, Container, Paragraph, Box, Flex, jsx } from "theme-ui"
import { PageProps, graphql } from "gatsby"
import { motion } from "framer-motion"

import type { NotFoundPageQuery } from "../../graphql-types"

import AguilaButton from "components/AguilaButton"
import Layout from "components/Layout"

const MotionBox = motion.li

export const query = graphql`
  query NotFoundPage {
    bullet1: file(relativePath: { eq: "bullethole-1.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
    target: file(relativePath: { eq: "aguila-target.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

interface NotFoundPageProps extends PageProps {
  data: NotFoundPageQuery
}

const NotFoundPage = ({ data, ...props }: NotFoundPageProps) => {
  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        delay: 0.2,
        staggerChildren: 0.3,
      },
    },
  }

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  }

  return (
    <Layout seo={{ metaTitle: "404 Not Found" }} location={props.location}>
      <Container sx={{ pt: 12, pb: 12, position: "relative" }}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "500px",
          }}
        >
          <Box sx={{ position: "relative", zIndex: 1 }}>
            <Heading variant="hero" sx={{ mb: 6 }}>
              Whoops! You missed your target.
            </Heading>
            <Paragraph variant="text.heading" sx={{ mb: 8 }}>
              404: Try again?
            </Paragraph>
          </Box>
          <Box sx={{ position: "relative", zIndex: 3 }}>
            <Paragraph sx={{ maxWidth: "350px", mb: 8 }}>
              Sorry, we can't find the page you're looking for. Let's get back
              to the site and take another shot at this.
            </Paragraph>
            <AguilaButton url="/" text="Back to the shooting range" />
          </Box>
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: ["flex-end", null, "center"],
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 0,
            }}
          >
            <Box
              sx={{
                width: ["150px", null, "300px"],
                height: ["150px", null, "300px"],
                mb: [18, null, 0],
              }}
            >
              <GatsbyImage
                image={data.target?.childImageSharp?.gatsbyImageData}
                alt="target"
              />
            </Box>
          </Flex>
          <motion.ul
            variants={container}
            initial="hidden"
            animate="show"
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              listStyle: "none",
              m: 0,
              p: 0,
              zIndex: 1,
            }}
          >
            <MotionBox
              variants={item}
              sx={{
                width: "50px",
                height: "50px",
                position: "absolute",
                transform: "rotate(50deg)",
                top: (Math.random() * 80).toFixed(2) + "%",
                left: (Math.random() * 80).toFixed(2) + "%",
                // top: top,
              }}
            >
              <GatsbyImage
                image={data.bullet1?.childImageSharp?.gatsbyImageData}
                alt="bullet hole"
              />
            </MotionBox>
            <MotionBox
              variants={item}
              sx={{
                width: "50px",
                height: "50px",
                position: "absolute",
                top: (Math.random() * 70).toFixed(2) + "%",
                left: (Math.random() * 70).toFixed(2) + "%",
                // top: top,
              }}
            >
              <GatsbyImage
                image={data.bullet1?.childImageSharp?.gatsbyImageData}
                alt="bullet hole"
              />
            </MotionBox>
            <MotionBox
              variants={item}
              sx={{
                width: "50px",
                height: "50px",
                position: "absolute",
                top: (Math.random() * 90).toFixed(2) + "%",
                left: (Math.random() * 90).toFixed(2) + "%",
                // top: top,
              }}
            >
              <GatsbyImage
                image={data.bullet1?.childImageSharp?.gatsbyImageData}
                alt="bullet hole"
              />
            </MotionBox>
            <MotionBox
              variants={item}
              sx={{
                width: "50px",
                height: "50px",
                position: "absolute",
                top: (Math.random() * 90).toFixed(2) + "%",
                left: (Math.random() * 90).toFixed(2) + "%",
                // top: top,
              }}
            >
              <GatsbyImage
                image={data.bullet1?.childImageSharp?.gatsbyImageData}
                alt="bullet hole"
              />
            </MotionBox>
            <MotionBox
              variants={item}
              sx={{
                width: "50px",
                height: "50px",
                position: "absolute",
                top: (Math.random() * 90).toFixed(2) + "%",
                left: (Math.random() * 90).toFixed(2) + "%",
                // top: top,
              }}
            >
              <GatsbyImage
                image={data.bullet1?.childImageSharp?.gatsbyImageData}
                alt="bullet hole"
              />
            </MotionBox>
            <MotionBox
              variants={item}
              sx={{
                width: "50px",
                height: "50px",
                position: "absolute",
                top: (Math.random() * 70).toFixed(2) + "%",
                left: (Math.random() * 70).toFixed(2) + "%",
                // top: top,
              }}
            >
              <GatsbyImage
                image={data.bullet1?.childImageSharp?.gatsbyImageData}
                alt="bullet hole"
              />
            </MotionBox>
          </motion.ul>
        </Box>
      </Container>
    </Layout>
  )
}
export default NotFoundPage
